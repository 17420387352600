import React from "react"
import { Layout } from "../components/Layout/Layout"
import { PageTitle } from "../components/PageTitle/PageTitle"
import { graphql } from "gatsby"

interface CourseScheduleProps {
  data: {
    cms: {
      page: {
        title: string
        kursplanFields: {
          kursplan: {
            sourceUrl: string
          }
        }
      }
    }
  }
}
export const CourseSchedule: React.FC<CourseScheduleProps> = ({ data }) => {
  return (
    <Layout withPadding>
      <div className="flex w-full flex-col items-center overscroll-x-auto">
        <PageTitle>{data.cms.page.title}</PageTitle>
        <div className="w-11/12 bg-white">
          <img
            src={data.cms.page.kursplanFields.kursplan.sourceUrl}
            alt="Kursplan gerade nicht Verfügbar"
            title="Kursplan"
          />
        </div>
      </div>
    </Layout>
  )
}
export default CourseSchedule

export const query = graphql`
  {
    cms {
      page(id: "/kursplan-2", idType: URI) {
        title
        kursplanFields {
          kursplan {
            sourceUrl
          }
        }
      }
    }
  }
`
